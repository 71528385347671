import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router";
import _ from "lodash";
import { PageLinks } from "../../common/page-links";
import { site_bviewing_agent_id } from "../../common/constant";

import "gatsby-theme-starberry-lomondgroup/src/components/BottomBtn/Btn.scss";

const Bottom = ({ property_data, pageurl }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const location = useLocation();
    var property_url = location.href;

    //console.log("Book a Viewing", pageurl);

    const trackBookaViewing = () => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Book a Viewing',
                'formType': pageurl,
                'formId': 'Book a Viewing',
                'formName': 'Book a Viewing - Rent',
                'formLabel': 'Book a Viewing'
            });
        }
    }
    
    const data = useStaticQuery(graphql`
    query OfficeContactQueryCustom{
      glstrapi {
        offices {
            office_crm_id
            mobile
            email
            lettings_email
          }
      }
    }`)

    var offices = data.glstrapi?.offices;

    let officeContact = _.find(offices, function (o) { return _.includes(_.split(o.office_crm_id, ','), property_data?.office_crm_id); });
    
    var to_email_id = process.env.GATSBY_BOOK_VIEWING_EMAIL ? process.env.GATSBY_BOOK_VIEWING_EMAIL : officeContact?.email ? officeContact?.email : "";

    if(property_data?.search_type === "lettings"){
        to_email_id = officeContact?.lettings_email ? officeContact?.lettings_email : to_email_id
    }

    //console.log("property_data", property_data);

    //console.log("GATSBY_BOOK_VIEWING_LINK", process.env.GATSBY_BOOK_VIEWING_LINK, property_data.address);
    //var book_a_viewing_link = process.env.GATSBY_BOOK_VIEWING_LINK+property_data?.crm_id
    var book_a_viewing_link = process.env.GATSBY_BOOK_VIEWING_LINK;
    var agent_id = site_bviewing_agent_id;

    if(_.includes(property_data.address, "Glasgow")){
        agent_id = "RFR-DJAlexanderGlasgow";
    }
    if(_.includes(property_data.address, "GLASGOW")){
        agent_id = "RFR-DJAlexanderGlasgow";
    }
    if(_.includes(property_data.address, "Edinburgh")){
        agent_id = "RFR-DJAlexanderEdinburgh"
    }
    if(_.includes(property_data.address, "EDINBURGH")){
        agent_id = "RFR-DJAlexanderEdinburgh"
    }
    
    if(process.env.GATSBY_BOOK_VIEWING_LINK){
        book_a_viewing_link = book_a_viewing_link.replace('{agent_id}', agent_id).replace('{unique_id}', property_data?.crm_id)
    }

    return (
        <React.Fragment>
            <section className="bottom-btn d-xl-none">
                <Container>
                    <Row>
                        <Col>
                            <div className={`btn-wrapper ${property_data?.search_type === "sales" && officeContact?.office_crm_id ? "" : "full_wdth_btn"}`}>
                                {(process.env.GATSBY_BOOK_VIEWING_LINK && property_data?.search_type === "lettings" && agent_id) ?
                                    <a href={"javascript:;"} onClick={() => {setModalShow(true); trackBookaViewing()}} data-link={book_a_viewing_link}  className="btn"
                                    > 
                                        <i className="icon-msg"></i> 
                                        <div className="d-md-flex d-none">Book a Viewing</div>
                                        <div className="d-flex d-md-none">Book a Viewing</div>
                                    </a>
                                : 
                                    <Link to={`/${PageLinks.book_a_viewing}/`} state={{ property_data, form_type: "book_a_viewing", property_url: property_url, to_email_id }}  className="btn" data-link={book_a_viewing_link}>
                                        <i className="icon-msg"></i> 
                                        <div className="d-md-flex d-none">Book a Viewing</div>
                                        <div className="d-flex d-md-none">Book a Viewing</div>
                                    </Link>
                                }

                                {property_data.status === "To Let" && 
                                    <Link to={`/${PageLinks.apply_to_rent}/`} state={{ property_addres: property_data.display_address, property_url: property_url, to_email_id }}  className="btn">
                                        <i className="home_icon"></i> 
                                        <div className="d-md-flex d-none">Apply to Rent</div>
                                        <div className="d-flex d-md-none">Apply</div>
                                    </Link>
                                }
                                
                                {officeContact?.mobile &&
                                    <a href={`tel:${officeContact?.mobile}`} className={`btn btn-outline mobile ${property_data?.search_type}`}>
                                        <i className="icon-call"></i> 
                                        <div className="d-md-flex d-none">{officeContact?.mobile}</div>
                                        <div className="d-flex d-md-none">Call</div>
                                    </a>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="book_a_viewing_ttl">
                        Book a Viewing
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="team_content col-md-12 col-lg-18 p-0">
                        <iframe src={book_a_viewing_link} height="600" width={"100%"} className="book_a_viewing" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default Bottom;
